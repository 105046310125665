import partner1 from './../assets/partner1.png';
import partner2 from './../assets/partner2.png';
import partner3 from './../assets/partner3.png';
import partner4 from './../assets/partner4.png';
import partner5 from './../assets/partner5.png';
import partner6 from './../assets/Harkani Logo Dark.png';


export const partnerData =[
    {
        "id" : 1,
        "image" : partner1,

    },
    {
        "id" : 2,
        "image" :partner2 ,

    },
    {
        "id" : 3,
        "image" : partner3,

    },
    {
        "id" : 4,
        "image" : partner4,

    },
    {
        "id" : 5,
        "image" : partner5,

    },
    {
        "id" : 6,
        "image" : partner3,

    },
    {
        "id" : 6,
        "image" : partner6,

    },
   
]