import React from 'react'
import './footer.css'
import logo from '../assets/logo.svg'
import mail from'../assets/image 5.svg'
import location from'../assets/image 6.svg'
import phone from'../assets/image 8.svg'
import sendIcon from '../assets/right arrow.svg'
import fBg from '../assets/f-bg.png'
const Footer = () => {
  return (
    <div className="footer-wrapper">
          <img className='footer-bg' src={fBg} alt="" />
    <div className='footer-container'>

      <div id='contact' className="left-f">
        <h1 className='footer-header'>GET IN TOUCH</h1>
        <img className='footer-logo' src={logo} alt="wirtu-construction" />
        <div className="adress">
          <div className="line">
            <img src={phone} alt="" />
            <p> +251-911-354-438</p>
            <p> +251 91 217 9787</p>
          </div>
          <div className="line">
            <img src={mail} alt="" />
            <p>info@wiirtuuconstruction.com</p>
          </div>
          <div className="line">
            <img src={location} alt="" />
            <p>Sarbet, Mechare Noc Bldg 1st Floor
                Addis Ababa, Ethiopia</p>
          </div>
        </div>
      <div className="social-links"></div>
      </div>
      
      
      <div className="right-f">
        <p>Let's begin turning your construction Vision into reality whether you have questions want to discuss a project or need expert advice our team is here to listen and assist you we believe in open communication and buildings strong connections with our clients reach out to us and together we will create spaces that exceed your expectation</p>
        <form action="">
          <input type="text" name="name" id="name" placeholder='Your Full Name' />
          <input type="email" name="email" id="email" placeholder='Your Email' />
          <textarea type="text" name="message" id="message" placeholder='Your Message' />
          <button className='submit' type='submit'>Send <img src={sendIcon} alt="" /></button>
        </form>
      </div>
    </div>
    </div>
  )
}

export default Footer