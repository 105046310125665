import React from 'react'
import './hero.css'
import Navbar from './Navbar'
import heroBg from '../assets/bg.png'
import logo from '../assets/logo Large.png'
import Hero2 from '../components/Hero2'
import { motion } from "framer-motion"
const Hero = () => {
  return (
   
    <div className='hero-wrapper'>

      <div className="snap hero1-container">
        <motion.img initial={{ opacity: 1, scale: 1 }}
              whileInView={{ opacity: 1, scale: 1.2 }}
            
              transition={{duration: 1, type:"spring"}}
              className='hero-bg' src={heroBg} alt="" />
          <Navbar />
          <motion.img 
          initial={{ opacity: 1, x: '-100px' }}
          whileInView={{ opacity: 1, x: '0px' }}

          transition={{duration: 1}}
          className='hero-img' src={logo}  alt="" />
      </div>
        


      <div className='snap hero2-container'>
          <div className="hero2-box">
              <motion.h1
              className='hero2-header'
                    initial={{ opacity: 1, x: "100px" }}
                    whileInView={{ opacity: 1, x: "0px" }}
                    transition={{duration: .8, type:"spring"}}>WHERE</motion.h1>
              <motion.h1
              className='hero2-header'
                    initial={{ opacity: 1, x: "100px" }}
                    whileInView={{ opacity: 1, x: "0px" }}
                    transition={{duration: .9, type:"spring"}}>Your Project</motion.h1>
                <motion.h1
                className='hero2-header'
                    initial={{ opacity: 1, x: "-100px" }}
                    whileInView={{ opacity: 1, x: "0px" }}
                    transition={{duration: 1, type:"spring"}}>is our</motion.h1>
          </div>
              <div className="box-2">
                    <h1 className='stroke'>priority construction</h1>
                    <p>Located in Oromia, Ethiopia, Wiirtuu Construction plc is a renowned contracting company. Since its founding in 2009, EC has worked to establish itself as a civil engineering and construction firm that prioritises project delivery timeliness and quality.</p>
          </div>
      </div>


    </div>
  )
}

export default Hero