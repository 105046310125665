import React, { useState } from 'react'
import './about.css'
import Collapsible from 'react-collapsible';
import aboutImg from '../assets/about us img.jpg'
import aboutBg from '../assets/about bg.png'
import mission from '../assets/mission.png'
import vision from '../assets/vision.png'
import value from '../assets/value.png'
import { motion } from "framer-motion"
const About = () => {


  const [expand, setExpand ] = useState(false);

  const expandAbout = () =>{
    setExpand(!expand);
    console.log(expand)
  }

  return (
    <div id='about' className="about-wrapper">
        <div className='about-container'>
          <div className="left">
            <img src={aboutImg} alt="" />
            <motion.h1 
            initial={{ opacity: 0, x: "-250px" }}
            whileInView={{ opacity: 1, x:"0px" }}
           
            transition={{duration: 0.7, type:"spring"}}
            >we</motion.h1>
            <motion.h1 
            initial={{ opacity: 0, x: "-250px" }}
            whileInView={{ opacity: 1, x:"0px" }}
            className='are'
            transition={{duration: 0.7, type:"spring"}}
            >are</motion.h1>
          </div>
          <div className="right">
            <motion.h1
            initial={{ opacity: 0, x: "-250px" }}
            whileInView={{ opacity: 1, x:"0px" }}
           
            transition={{duration: 1, type:"spring"}}
            >More Than just builders</motion.h1>
            <motion.p
            initial={{ opacity: 0, x: "-270px" }}
            whileInView={{ opacity: 1, x:"0px" }}
            transition={{duration: 1.5, type:"spring"}}
            >Wiirtuu Construction Plc has been providing the construction industry with a special service.  It has grown into a respectable company whose industry-serving inventiveness, experience, and skills are at the disposal of the sector. Since it is a new company that only focuses on general construction, it will strive to build credibility and a solid reputation via the calibre of its work and its dedication to meeting tight deadlines.</motion.p>
            
      <Collapsible trigger="Read More" triggerWhenOpen="Less">
        <p>Wiirtuu Construction Plc's eligibility, promising potential, and credibility is to be taken into consideration for carrying out infrastructure development or construction projects, as accomplishing any long-term goal or purpose demands careful selection of a competent organization.</p>
      </Collapsible>
{/* 
            <div className="read-cta">
              <a onClick={expandAbout} href='#'>read more..</a>
            </div> */}
   
          </div>
          <img className='about-bg' src={aboutBg} alt="" />
        </div>


<div className="mission-container">

        <div className="mission">
          <motion.img
          initial={{ opacity: 1, scale: 0 }}
            whileInView={{ opacity: 1, scale:1 }}
            
            transition={{duration: .7, type:"spring"}}
          src={mission} alt="" />
          <div className="border-box">
          <h1>Mission</h1>
          <motion.p
           initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}
          
          transition={{duration: .7, type:"spring"}}
          
          >to uphold safety, integrity, and uniformity in our working methods in order to enhance the calibre of construction work. By introducing the newest technologies available, we aim to provide the best construction work possible.</motion.p>
          </div>
        </div>
        <div className="mission">
          <motion.img
          initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}
          
          transition={{duration: .7, type:"spring"}}
          src={vision} alt="" />
          <div className="border-box">
          <h1>Vision</h1>
          <motion.p
           initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}
          
          transition={{duration: .7, type:"spring"}}
          
          >We  aspire to be among the top Engineering, Procurement and Construction (EPC)      companies of our country by undertaking the remarkable and challenging      construction projects. We want to be the preferred contracting company      when it comes to industrial and residential projects.</motion.p>
          </div>
        </div>
        <div className="mission">
          <motion.img
          initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}
  
          transition={{duration: .7, type:"spring"}}
          src={value} alt="" />
          <div className="border-box">
          <h1>Value</h1>

            <ul>
              <motion.li
                  initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}

          transition={{duration: .7, type:"spring"}}
          ><span>Quality:</span> Maintain the quality of work on the field and even in office premises. Quality of work is the key to success and more opportunities.</motion.li>
              <motion.li
                  initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}

          transition={{duration: .7, type:"spring"}}
          ><span>Timeliness:</span> Deliver the work on or before the committed time. Valuing a client's time will always bring glory to the company which will ultimately help to acquire more valuable projects.</motion.li>
              <motion.li
                  initial={{ opacity: 1, scale: 0 }}
          whileInView={{ opacity: 1, scale:1 }}

          transition={{duration: .7, type:"spring"}}
          ><span>Opportunities:</span> Create opportunities for the employees. We will grow in this domain by the teamwork of our employees. Keep a rewarding attitude towards the employees of this company.</motion.li>
            </ul>

          </div>
        </div>
</div>

    </div>
  )
}

export default About