import React, { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { partnerData } from "../components/parnterData";
import './partner.css'
import sisterLogo from '../assets/sister logo.jpg'
const Partner = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useLayoutEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div id="partner" className="bg-white py-40 ">
      <div className="mx-auto  ">
        <div className="  lg:mx-0">
          <h2 className="partner-header">
            OUR PARTNERS
          </h2>
        </div>

        <div className="sister-company">
          <img src={sisterLogo} alt="" className="sister-logo" />
          <h2>Together with sister company </h2>
        </div>


        <div className=" h-2 w-full bg-primary" />
        <Slider
          {...settings}
          className="partners-container"
        >
          {partnerData?.map((partner) => {
            return (
              <li key={partner._id} className="">
                <img
                  width={1000}
                  height={1000}
                  className="mx-auto w-[200px] h-[120px] rounded-md object-contain"
                  src={partner.image}
                  alt=""
                />
              </li>
            );
          })}
        </Slider>
        <div className=" h-2 w-full bg-primary" />
      </div>
    </div>
  );
};

export default Partner;
