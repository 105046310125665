import logo from './logo.svg';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import Offers from './components/Offers';
import Why from './components/Why';
import Partner from './components/Partner';
import Projects from './components/Projects';
import Team from './components/Team';
import Footer from './components/Footer';
import Copyright from './components/Copyright';
import Hero2 from './components/Hero2';
function App() {
  return (
    <div className="App">
        <Hero />
        <About />
        <Offers/>
        <Why />
        <Partner />
        <Projects />
        <Team />
        <Footer />
        <Copyright />
    </div>
  );
}

export default App;
