import React from 'react'
import './copyright.css'
const Copyright = () => {
  return (
    <div className="copyright-wrapper">

    <div className='copyright-container'>
        <p>&copy; 2024 HARKANI PLC</p>
        <ul className="footer-links">
            <li><a href="#about">About us</a></li>
            <li><a href="#services">Our services</a></li>
            <li><a href="#projects">Our Projects</a></li>
            <li><a href="#contact">Get in touch</a></li>
        </ul>
    </div>
    </div>
  )
}

export default Copyright