import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './navbar.css'
import logo from '../assets/logo.svg'
import openIcon from '../assets/open.svg'
import closeIcon from '../assets/close.svg'
import { motion } from "framer-motion"
const Navbar = () => {

   const [ opened, setOpened ] = useState(false); 
   const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const openMenu = () =>{
    setOpened(!opened);
  }

  return (
    <div className='nav-bar'>
        <img src={logo} alt="" className="logo" />
        {
            isPortrait ? ''
            : <ul className="nav-links">
            <li><a href="#about">About us</a></li>
            <li><a href="#services">Our services</a></li>
            <li><a href="#projects">Our Projects</a></li>
            <li><a href="#contact">Get in touch</a></li>
        </ul>
        }
        {
           opened? <motion.ul 
           initial={{ opacity: 0, y: "-100px" }}
            whileInView={{ opacity: 1, y: "0px" }}
            viewport={{ once: false }}
   className="nav-links-mobile">
           <motion.li  onClick={openMenu}  
            ><a href="#about">About us</a></motion.li>
           <motion.li   onClick={openMenu} 
            ><a href="#services">Our services</a></motion.li>
           <motion.li   onClick={openMenu} 
             ><a href="#projects">Our Projects</a></motion.li>
           <motion.li   onClick={openMenu} 
            ><a href="#contact">Get in touch</a></motion.li>
       </motion.ul>
       :
       ''
        }
        
        {
            isPortrait ? <div className="menu-icon">
            <img onClick={openMenu} className='open-menu' src={opened?closeIcon :openIcon} alt="" />
        </div>: ''
        }
        

    </div>
  )
}

export default Navbar