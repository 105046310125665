import React from 'react'
import Slider from "react-slick";
import '../components/team.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team1 from '../assets/team1.png'
import team2 from '../assets/team2.png'
import team3 from '../assets/team3.jpg'
import team4 from '../assets/team4.jpg'
import PrevIcon from '../assets/left arrow.svg'
import nextIcon from '../assets/right arrow.svg'
const Team = () => {
    const settings = {
        infinite : true,
        slidesToShow : 3,
        slidesToScroll : 2,
        InitalSlide : 0,

        responsive : [{
            breakpoint : 1000,
            settings :{
                slidesToShow: 2,
                slidesToScroll:1,
                autoplay : true,
                infinite :true,
            }
        }],
        responsive : [{
            breakpoint : 768,
            settings :{
                slidesToShow: 1,
                slidesToScroll:1,
                autoplay : true,
                infinite :true,
            }
        }]
    };
      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
            
          />
        );
      }
      
  return (
    <div className="Team-wrapper">
        <h1 className='team-header'>Our Team</h1>
    <div className='Team-container'>
        <Slider {...settings}>
        <div className="person">
            <img src={team1} alt="" />
            <h3>Geleta Bedasa</h3>
            <h5>CEO and Project Manager</h5>
        </div>
        <div className="person">
            <img src={team2} alt="" />
             <h3>Geleta Bedasa</h3>
            <h5>CEO and Project Manager</h5>
        </div>
        <div className="person">
            <img src={team3} alt="" />
             <h3>Geleta Bedasa</h3>
            <h5>CEO and Project Manager</h5>
        </div>
        <div className="person">
            <img src={team4} alt="" />
             <h3>Geleta Bedasa</h3>
            <h5>CEO and Project Manager</h5>
        </div>
        
        </Slider>
        {/* <div className="team-buttons">
            <img src={PrevIcon} alt="" />
            <img src={nextIcon} alt="" />
        </div> */}
    </div>
    </div>
  )
}

export default Team