import React, { useState } from 'react'
import Slider from "react-slick";
import '../components/offers.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import offerImg from '../assets/service img.png'
import PrevIcon from '../assets/left arrow.svg'
import nextIcon from '../assets/right arrow.svg'
import buiding from '../assets/building.webp'
import irrigation from '../assets/Irrigation.webp'
import road from '../assets/road.webp'
import foundation from '../assets/foundation.webp'
import plumb from '../assets/plumb.webp'
import electro from '../assets/electro.webp'
import paint from '../assets/paint.webp'
import landscape from '../assets/landscape.webp'
const Offers = () => {

const [ slideCount, setSlideCount ] =useState(1);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
      };

      function SampleNextArrow(props) {

        setSlideCount(3);
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
            
          />
        );
      }
      
  return (
    <div className="offers-wrapper">
        <h1 className="offers-header">What we Offer</h1>
        {/* <h1 className='offers-counter'>{slideCount}/11</h1> */}
        <div id='services' className='offers-container'>
          
            <Slider {...settings}>
            <div className="offer">
                <img src={road} alt="" />
                <h3>Road Works</h3>
                <p>Road works including gravel, asphalt, asphalt concrete and reed roads & bridges, Drainage Work, Culverts Work, Gabion Work and etc…..</p>
            </div>
            <div className="offer">
                <img src={irrigation} alt="" />
                <h3> Water Work General Constructions</h3>
                <ul className='offer-list'>
                  <li>Irrigation</li>
                  <li>Water Supply</li>
                  
                </ul>
            </div>
            <div className="offer">
                <img src={buiding} alt="" />
                <h3>Building works</h3>
                <ul className='offer-list'>
                  <li>Schools, College, University</li>
                  <li>Residential Building, Apartment and Condominiums</li>
                  <li>oGovernmental Offices</li>
                  <li>Commercial Buildings</li>
                  <li>Non-Governmental and Government Building Construction Projects, etc</li>
                </ul>
            </div>
            <div className="offer">
                <img src={foundation} alt="" />
                <h3>Foundation Works</h3>
            </div>
            <div className="offer">
                <img src={landscape} alt="" />
                <h3>General Landscaping works/ Road safety sign</h3>
            </div>
            <div className="offer">
                <img src={offerImg} alt="" />
                <h3>Construction completion/ Site maintenance</h3>
            </div>
            <div className="offer">
                <img src={electro} alt="" />
                <h3>Electomechanical works</h3>
            </div>
            <div className="offer">
                <img src={paint} alt="" />
                <h3>Painting and decoration work</h3>
            </div>
            <div className="offer">
                <img src={plumb} alt="" />
                <h3>Plumbing and sanitation work in the building and etc.……</h3>
            </div>
            </Slider>
            <div className="buttons" style={{display: "none"}}>
                <img src={PrevIcon} alt="" />
                <img src={nextIcon} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Offers