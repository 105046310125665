import project1 from "./../assets/project1.jpg";
import project2 from "./../assets/project 2.jpg";
import project3 from "./../assets/project 3.jpg";
import project4 from "./../assets/project 4.jpg";
import project5 from "./../assets/project 5.jpg";
import project6 from "./../assets/project 6.jpg";
import project7 from "./../assets/project 7.jpg";
import project8 from "./../assets/project 8.jpg";
import PrevIcon from '../assets/left arrow.svg'
import nextIcon from '../assets/right arrow.svg'
import blackArrow from '../assets/arrow black.svg'
import Slider from "react-slick";
import { motion } from "framer-motion"
import './projects.css'
import { useState } from "react";
export default function Projects() {
  const [ next, setNext ] = useState(false);
  const nextProjects = () =>{
    setNext(!next)
  }
  return (
    <div id="projects" className="xl:mx-40 mx-1 my-10 max-h-full projects-container ">

      {/* <div className="f-shape">
        <div className="col-l">
          <div className="item item-1">
                    <img src={project1}/>
          </div>
          <div className="item item-2">
                    <img src={project2}  />
          </div>
        </div>
        <div className="col-r">
          <div className="item item-3">
                    <img src={project3} />
          </div>
          <div className="item item-4">
                    <img src={project4} />
          </div>
        </div>

        
      </div> */}


      <div className="flex justify-between items-center px-5 mb-10">
            <h2 className="projects-header">RECENT PROJECTS</h2>
            <button onClick={nextProjects} className="flex justify-center g-10 items-center w-1/4 gap-2">next<img className="w-10" src={blackArrow} alt="" /> </button>
      </div>

      {
      
      next? 
      <div className=" ml-0  space-y-5 md:grid md:grid-cols-2 lg:gap-5 relative">
      <div className="flex flex-col  space-y-5 justify-center align-center">
        <motion.div
        initial={{ opacity: 1, x: "-100px" }}
        whileInView={{ opacity: 1, x:"0px" }}
        transition={{duration: 0.7, type:"spring"}}
        className="item">
              <img src={project5} className=" w-[100%] lg:w-[100%] " />
        </motion.div>
        <motion.div
        initial={{ opacity: 1, x: "-100px" }}
        whileInView={{ opacity: 1, x:"0px" }}
        transition={{duration: 0.7, type:"spring"}}
        className="item">
              <img src={project6} className=" w-[100%] lg:w-[100%] " />
        </motion.div>
        
      </div>
      <div className=" space-y-5 -top-5 justify-center">
      <motion.div
      initial={{ opacity: 1, x: "-100px" }}
      whileInView={{ opacity: 1, x:"0px" }}
      transition={{duration: 0.7, type:"spring"}}
      className="item">
              <img src={project7} className=" w-[100%] lg:w-[100%] " />
        </motion.div>
      <motion.div
      initial={{ opacity: 1, x: "-100px" }}
      whileInView={{ opacity: 1, x:"0px" }}
      transition={{duration: 0.7, type:"spring"}}
      className="item">
               <img src={project8} className=" w-[100%] lg:w-[100%] " />
        </motion.div>
      </div>
    </div>
    
    
    :

      
    <div className=" ml-0  space-y-5 md:grid md:grid-cols-2 lg:gap-5 relative">
    <div className="flex flex-col  space-y-5 justify-center align-center">
      <motion.div
      initial={{ opacity: 1, x: "-100px" }}
      whileInView={{ opacity: 1, x:"0px" }}
      transition={{duration: 0.7, type:"spring"}}
      className="item">
            <img src={project1} className=" w-[100%] lg:w-[100%] " />
            <p className="desc">Construction of G+3 Jimma Rare Worefa Agriculture and Natural Resource Office</p>
            <div className="text-bg"></div>
      </motion.div>
      <motion.div
      initial={{ opacity: 1, x: "-100px" }}
      whileInView={{ opacity: 1, x:"0px" }}
      transition={{duration: 0.7, type:"spring"}}
      className="item">
            <img src={project2} className=" w-[100%] lg:w-[100%] " />
            <p className="desc">Expansion of Lalo Kile TVET College</p>
            <div className="text-bg"></div>
      </motion.div>
    </div>
    <div className=" space-y-5 -top-5 justify-center">
    <motion.div
    initial={{ opacity: 1, x: "-100px" }}
    whileInView={{ opacity: 1, x:"0px" }}
    transition={{duration: 0.7, type:"spring"}}
    className="item">
            <img src={project4} className=" w-[100%] lg:w-[100%] " />
            <p className="desc">Renovation and Construction Works of Adama Continuous Professional Development Training Center</p>

            <div className="text-bg"></div>
      </motion.div>
    <motion.div
    initial={{ opacity: 1, x: "-100px" }}
    whileInView={{ opacity: 1, x:"0px" }}
    transition={{duration: 0.7, type:"spring"}}
    className="item">
             <img src={project3} className=" w-[100%] lg:w-[100%] " />
             <p className="desc">Construction of Ifa Boru Sadi Caammoo primary School</p>

            <div className="text-bg"></div>
      </motion.div>
    </div>
  </div>
    
    
    
     }





      


      {/* <div className="buttons">
            <img src={PrevIcon} alt="" />
            <img src={nextIcon} alt="" />
        </div> */}
    </div>
  );
}
