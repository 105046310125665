import Image from "./../assets/why img.jpg";
import './why.css'
import { motion } from "framer-motion"
export default function Why() {
  return (
    <div className="why-container">
        <h1 className="why-header">Why Choose us</h1>
        <div className="why-block">
     
                 <motion.img
                  initial={{ opacity: 1, x: "100px" }}
                  whileInView={{ opacity: 1, x: "0px" }}
                  transition={{duration: .9, type:"spring"}}
                  className="why-img" src={Image} alt="" />

          
          <motion.div 
             initial={{ opacity: 1, y: "100px" }}
             whileInView={{ opacity: 1, y: "0px" }}
             transition={{duration: .9, type:"spring"}}
             className="right-why">
                <p>Since achieving any long-term goal or objective requires careful selection of a competent firm, this company has been created with, promising potential, and credibility to be taken into consideration for carrying out infrastructure development or construction projects. We Are :</p>
                <ul className="why-list">
                    <li>Experienced Team of Engineers</li>
                    <li>Using Latest equipment in the Construction Industry</li>
                    <li>Work as per Quality Standards</li>
                    <li>Timely Deliverables</li>
                </ul>
          </motion.div>
          </div>
          <div className="numbers">
            <div className="number">
                <motion.h1
                initial={{ opacity: 1, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
          
            transition={{duration: .7, type:"spring"}}
                >15+</motion.h1>
                <motion.p
                 initial={{ opacity: 1, y: "-100px" }}
       whileInView={{ opacity: 1, y: "0px" }}

       transition={{duration: 1, type:"spring"}} className="divide">YEARS OF EXPERIENCE</motion.p>
            </div>
            <div className="number">
                <motion.h1
                initial={{ opacity: 1, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
          
            transition={{duration: .9, type:"spring"}}
                >30+</motion.h1>
                <motion.p
                 initial={{ opacity: 1, y: "-100px" }}
       whileInView={{ opacity: 1, y: "0px" }}

       transition={{duration: 1, type:"spring"}} className="divide">PROJECTS COMPLETED</motion.p>
            </div>
            <div className="number">
                <motion.h1
                initial={{ opacity: 1, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
          
            transition={{duration: 1.2, type:"spring"}}
                >10+</motion.h1>
                <motion.p
                 initial={{ opacity: 1, y: "-100px" }}
       whileInView={{ opacity: 1, y: "0px" }}

       transition={{duration: 1, type:"spring"}}>CITIES WE ARE PRESENT IN</motion.p>
            </div>
     
        </div>
        
        
        
    </div>
  );
}
